import { Type } from 'class-transformer'
import HomeworkPaper from './HomeworkPaper'
import { WorkathonInfo } from './WorkathonInfo'
import Homework from './Homework'

export default class HomeworkInfo extends Homework {
  @Type(() => WorkathonInfo)
  workathon: WorkathonInfo

  @Type(() => HomeworkPaper)
  homeworkPapers: HomeworkPaper[]
}
