import { Type } from 'class-transformer'
import { Unit } from "./Unit"
import _ from 'lodash'

export default class UnitModule {
  id: number
  name: string
  @Type(() => Unit) units: Unit[]
  unitTagList: any
  _units: Record<number, Unit>

  buildUnitTagList(): any {
    this.unitTagList = _.flatMap(this.units, (it) => it.tagList)
    return this.unitTagList
  }
}
