import { Type } from "class-transformer"
import District from "./District"

export default class School {
  id: number
  name: string
  allowPersonalPurchase: boolean

  @Type(() => District) district: District
  regionName: string
  enableTeaching: boolean
}
