import { Type } from "class-transformer"
import ResourceInfo from "./ResourceInfo"

export default class Subject {
  id: number
  code: string
  ticketCode: string
  name: string
  description: string

  @Type(() => ResourceInfo) posterInfo: ResourceInfo
}
