import { Type } from "class-transformer"
import { Order } from "."

export default class Payment {
  id: number
  state: string
  message: string
  orderId: number
  prepayId: string
  codeUrl: string
  token: string
  createdAt: string
  updatedAt: string
  lockVersion: number

  @Type(() => Order) order: Order
  payParams: Object
}
