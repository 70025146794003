import { Type } from "class-transformer"
import ResourceInfo from "./ResourceInfo"

export default class Schoolbook {
  id: number
  editionId: number
  volumeName: string
  semester: string
  semesterName: string
  editionName: string
  editionSchooling: boolean
  subjectId: number
  subjectName: string
  subjectCode: string
  gradeCode: string
  gradeName: string
  coverUrl: string

  @Type(() => ResourceInfo)
  coverInfo: any
}
