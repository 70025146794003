export default class StudentDeactiveRecord {
  id: number
  state: "pending" | "deactived" | "cancelled"
  username: string
  createdAt: string
  clazzFullname?: string
  schoolName?: string
  cityName?: string
  primaryGradeId?: number
}
