import { Type } from 'class-transformer'
import Subject from './Subject'
import Clazz from './Clazz'
import Homework from './Homework'

export class HomeworkStat {
  homeworkId: number
  checkedHomeworkPapersCount: number
  submittedHomeworkPapersCount: number
  confirmedHomeworkPapersCount: number
  processedHomeworkPapersCount: number

  constructor(homework?: Homework) {
    if (homework) {
      this.homeworkId = homework.id
    }
    this.checkedHomeworkPapersCount = 0
    this.submittedHomeworkPapersCount = 0
    this.confirmedHomeworkPapersCount = 0
    this.processedHomeworkPapersCount = 0
  }

  finishedHomeworkPapersCount() {
    return this.checkedHomeworkPapersCount + this.submittedHomeworkPapersCount + this.processedHomeworkPapersCount + this.confirmedHomeworkPapersCount
  }
}

export class WorkathonInfo {
  id: number
  batchNo: string
  examNo: any
  remark: string
  publishedOn: string
  publishedAt: string
  deadlineAt: string
  assignerName: string
  scene: string
  sceneText: string
  createdAt: string
  homeworkPapersCount: number
  workathonersCount: number

  @Type(() => HomeworkStat)
  homeworkStats: HomeworkStat[]

  @Type(() => Subject)
  subject: Subject

  @Type(() => Clazz)
  clazz: Clazz

  @Type(() => Homework)
  homeworks: Homework[]
}
