import { Type } from "class-transformer"
import Bundle from "./Bundle"
import Grade from "./Grade"

export default class JackpotBundleRelation {
  jackpotId: number

  @Type(() => Bundle)
  bundle: Bundle

  @Type(() => Grade)
  grade: Grade
}
