export default class Clazz {
  id: number
  code: string
  fullname?: string
  archived: string
  gradeName: string

  schoolId: number
  schoolName: string
  schoolRegionName : string
}
