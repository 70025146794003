import { Type } from "class-transformer"
import { CoursewareFamily, Knowledge, QuestionCategory, StudentshipRanking, WeeklyReport } from "."

class WeeklyRecordHead {
  id: number
  studentId: number
  issue: string
  subjectId: number
}

export class WeeklyWorkoutRecord extends WeeklyRecordHead {
  familyId: number
  @Type(() => CoursewareFamily) family: CoursewareFamily

  questionsCount: number
  homeworkPapersCount: number
  accuracy: number
}

export class WeeklyTaughtRecord extends WeeklyRecordHead {
  familyId: number
  @Type(() => CoursewareFamily) family: CoursewareFamily

  questionsCount: number
  answerSheetsCount: number
  accuracy: number
}
export class WeeklyErrorbagRecord extends WeeklyRecordHead {
  count: number
  recordId: number
  recordType: 'Knowledge' | 'QuestionCategory'
  @Type(() => Knowledge) knowledge?: Knowledge
  @Type(() => QuestionCategory) questionCategory?: QuestionCategory
}

export class WeeklyReportInfo extends WeeklyReport {
  @Type(() => WeeklyWorkoutRecord) workoutRecords?: WeeklyWorkoutRecord[]
  @Type(() => WeeklyTaughtRecord) taughtRecords?: WeeklyTaughtRecord[]
  @Type(() => WeeklyErrorbagRecord) weeklyErrorbagRecords?: WeeklyErrorbagRecord[]
  // @Type(() => StudentshipRanking) currentRankings?: StudentshipRanking[]
  // @Type(() => StudentshipRanking) lastRankings?: StudentshipRanking[]
}
