import { Type } from 'class-transformer'
import { Student } from '.'

export default class Order {
  token: string
  bundle_name: string
  created_at: string
  no: string
  quantity: number
  state: string
  subtitle: any

  @Type(() => Student) student: Student
}
