import { Type } from "class-transformer"
import Clazz from "./Clazz"
import School from "./School"

export default class StudentExtra {
  @Type(() => Clazz) primaryClazz?: Clazz

  @Type(() => School) primarySchool?: School

  unreadedNoticesCount: number
  unfinishedHomeworksCount: number
}
