export default class Child {
  id: number
  studentId: number
  authenticationId: number
  createdAt: Date
  updatedAt: Date
  lastPasswordChangedAt: string
  memberExpiredOn: string
  studentName: string
  studentNickname: string
  studentPhone: string
  studentUsercode: string
  cityName?: string
  clazzFullname?: string
  gradeName?: string
  schoolName?: string
  defaultAllowPersonalPurchase?: boolean
  studentUserDeactiveRecordId: number
  studentUserDeactiveRecordState: "pending" | "deactived" | "cancelled"
}
