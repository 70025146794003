import { Type } from "class-transformer";
import UnitModule from "./UnitModule";
import Schoolbook from "./Schoolbook";
import { SummaryByUnitModule } from "./SummaryByUnitModule";

export class StatByUnitModules {
  @Type(() => Schoolbook)
  schoolbook: Schoolbook

  @Type(() => UnitModule)
  unitModules: UnitModule[]

  @Type(() => SummaryByUnitModule)
  summary: SummaryByUnitModule[]
}
