import { Type } from "class-transformer"
import { Bundle, Grade, Student, Textbook, Event, Subject } from "."

export default class TextbookInfo extends Textbook {
  @Type(() => Grade) grade: Grade
  @Type(() => Student) student: Student
  @Type(() => Bundle) bundles: Bundle[]
  @Type(() => Event) events: Event[]
  @Type(() => Subject) subject: Subject
}
