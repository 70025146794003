export default class Bundle {
  id: number
  name: string
  originalPrice: string
  price: string
  token: string
  channel: "trial" | "web" | "agent" | "event"
  syscode: string
  slogan: string
  visible: boolean
}
