import { moment } from "../lib/moment"

export class WeeklyReport {
  id: number
  issue: string
  studentId: number

  issueNo() {
    const issueDate = moment(this.issue)
    return Math.ceil(issueDate.date() / 7).toString()
  }

  issueMonthOn() {
    const issueDate = moment(this.issue)
    return issueDate.startOf('month').format()
  }

  issueStartOn() {
    return moment(this.issue).format()
  }

  issueEndOn() {
    const issueDate = moment(this.issue)
    return issueDate.endOf('week').format()
  }

  nextIssueStartOn() {
    const issueDate = moment(this.issue)
    return issueDate.add(1, 'weeks').format()
  }
}
