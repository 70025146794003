import { Type } from 'class-transformer'
import Schoolbook from './Schoolbook'

export default class Homework {
  allowDisplayCorrect: boolean
  allowPublishedScore: string[]
  checkMethod: string
  coursewareCode: string
  coursewareId: number
  coursewareName: string
  coursewareStandard: string
  coursewareSubhead: string
  coursewareTextbookName: string
  coursewareToken: string
  coursewareTotalScore: string
  coursewareType: string
  coursewareFamilyName: string
  coursewareModelName: string
  examTime: any
  homeworkPapersCount: number
  id: number
  ownership: string
  standard: string
  testPaperPartitionId: number
  testPaperPartitionName: string
  ticketCode: any
  workathonId: number
  stageId: number[]
  stages: any[]
  handwritingMode: "enabled" | "disabled" | "available"
  partitionName: any
  partitionId: any
  stageType: string

  @Type(() => Schoolbook)
  schoolbook: Schoolbook
  coursewareDataVersion: string
}
