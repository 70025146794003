export default class Redemption {
  alreadyRegistered: boolean
  couponId: number
  id: number
  state: "pending" | "confirmed"
  token: string
  jackpotToken: string
  userId: number
  registeredRedirectUrl: string
}
