import { Type } from 'class-transformer'
import Subject from "./Subject"
import Clazz from "./Clazz"
import Homework from './Homework'

export default class Workathon {
  id: number
  batchNo: string
  examNo: any
  remark: string
  publishedOn: string
  publishedAt: string
  deadlineAt: string
  assignerName: string
  scene: string
  sceneText: string
  createdAt: string
  homeworkPapersCount: number
  workathonersCount: number

  @Type(() => Subject)
  subject: Subject

  @Type(() => Clazz)
  clazz: Clazz

  @Type(() => Homework)
  homeworks: Homework[]
}
