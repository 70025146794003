export default class Knowledge {
  id: number
  name: string
  code: string
  subjectId: number
  ancestry: string | null
  ancestryDepth: number
  parentId: number
  visible: boolean
  ancestorNames: string[]

  ancestry_ids() { return (this.ancestry ?? "").split('/').map((it) => parseInt(it)) }
}
