import momentInstance from 'moment'
import 'moment/dist/locale/zh-cn.js'

const formats = {
  "date": "YYYY-MM-DD",
  "datetime": "YYYY-MM-DD HH:mm",
  "year_month": "YYYY 年 MM 月",
  "month_day": "MM 月 DD 日",
}

type FormatKeys = keyof typeof formats

export function format(value, format: FormatKeys) {
  if (!value) { return null }
  const pattern = formats[format]
  return momentInstance(value).format(pattern)
}

export const moment = momentInstance
