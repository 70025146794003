export default class District {
  id: number
  code: string
  name: string
  provinceId: number
  provinceName: string
  cityId: number
  cityName: string

  fullnames() {
    return [ this.provinceName, this.cityName, this.name ]
  }
}
