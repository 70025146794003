import { Type } from "class-transformer"
import Schoolbook from "./Schoolbook"
import { SummaryByTextbook } from "./SummaryByTextbook"
import Textbook from "./Textbook"

export class StatByTextbook {
  @Type(() => Schoolbook)
  schoolbook: Schoolbook

  @Type(() => Textbook)
  textbooks: Textbook[]

  @Type(() => SummaryByTextbook)
  summary: SummaryByTextbook[]
}
