import { Type } from 'class-transformer'
import { Bundle, Event, Grade, Textbook } from '.'

export default class Introduction {
  id: number
  coursewareId: number
  homeworkId: number
  schoolbookId: number
  state: string
  studentId: number
  subjectId: number
  textbookId: number
  ticketCode: string

  // TODO
  token: string

  @Type(() => Textbook) textbook: Textbook
  @Type(() => Grade) grade: Grade
  @Type(() => Bundle) bundles: Bundle[]
  @Type(() => Event) events: Event[]
}
