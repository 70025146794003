import _ from "lodash"
import { Grade, StudentExtra, Subscription } from "."
import { Type } from "class-transformer"

export default class Student {
  id: number
  name: string
  nickname: string
  school: string
  usercode: string
  phone: string
  memberExpiredOn: string
  createdAt: string
  lastPasswordChangedAt: string

  @Type(() => Grade) primaryGrade: Grade
  @Type(() => Subscription) subscriptions: Subscription[]
  @Type(() => StudentExtra) studentExtra: StudentExtra

  subscriptionActived(ticketCode) {
    if (_.isNull(ticketCode)) {
      return true
    }

    return _.some(this.subscriptions, function(subscription) {
      return !subscription.expired() && _.includes(subscription.ticketCodes, ticketCode)
    })
  }
}
