import { Type } from "class-transformer"
import { Bundle, Event } from "."

class EventItem {
  id: number
  gradeIds: number[]
  eventId: number
  @Type(() => Bundle) bundle: Bundle
}

export default class EventInfo extends Event {
  @Type(() => EventItem) eventItems: EventItem[]
}
