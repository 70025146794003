import { moment } from "../lib/moment"

export default class Subscription {
  id: number
  expiredOn: string
  productId: number
  remainingDays: string
  ticketCodes: any[]
  name: string

  expired() {
    if (this.expiredOn === null || this.expiredOn === undefined) {
      return true
    }

    const currentDate = moment().startOf('day')
    const expiredOnDate = moment(this.expiredOn)

    return expiredOnDate.isBefore(currentDate)
  }
}
