export default class HomeworkPaper {
  id: number
  homeworkId: number
  score: any
  overdue: boolean
  totalScore: any
  accuracy: any
  submissionCount: number
  unrevisedCount: any
  studentId: number
  state: "started" | "submitted" | "processed" | "checked" | "confirmed" | "rejected"
  revised: any
  finishedAt: any
  spentTime: any
  submittedAt: any
  rejectedAt: any
  processedAt: any
  confirmedAt: any

  isFinished() {
    return [ "submitted", "checked", "processed", "confirmed" ].includes(this.state)
  }
}
