import { Type } from 'class-transformer'
import { HomeworkPaper, Workathon } from '.'

export class WorkathonerInfo {
  rowId: string

  @Type(() => Workathon)
  workathon: Workathon

  @Type(() => HomeworkPaper)
  homeworkPapers: HomeworkPaper[]
}
